import 'patient/styles/responsive.css';
import 'react-calendar/dist/Calendar.css';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';
import { useEffect, useState } from 'react';
import { mainContentAlignCenter, mustRedirectOnWeb } from 'shared/services/EnvService';
import { PatientRoutes } from 'patient/PatientRoutes';
import {
  registerPatientLocalEvents,
  removePatientLocalEvents,
} from 'shared/modules/PatientEventsListener';
import {
  getPatientAppointmentState,
  getAuthState,
  getPushesState,
} from 'shared/redux/src/StatesGetter';
import FooterSection from 'shared/components/FooterSection';
import { useLocation, useNavigate } from 'react-router-dom';
import { appointmentLocalStepsRequest } from 'shared/redux/actions/PatientAppointmentActions';
import ThemeExporter from 'shared/themes/Themes';
import {
  fetchNotificationsSettingsRequest,
  fetchPushesRequest,
} from 'shared/redux/actions/NotificationActions';
import { fetchPatientProfileRequest } from 'shared/redux/actions/PatientProfileActions';
import HeaderSection from 'patient/components/HeaderSection';
import PatientPushNotifier from 'shared/modules/PatientPushNotifier';
import PatientStickyBar from 'patient/components/PatientStickyBar';
import Utils from 'shared/modules/Utils';
import {
  getAppointmentSettingsRequest,
  getCurrentAppointmentRequest,
} from 'shared/redux/actions/AppointmentActions';
import UnsupportedBrowser from 'shared/screens/UnsupportedBrowser';
import NavigationService from '../shared/services/NavigationService';

const PatientApp = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const location = useLocation();
  const theme = ThemeExporter.PatientTheme();
  const { isLoggedIn, hasFullAccess } = useSelector(getAuthState, shallowEqual);
  const { unseen = 0 } = useSelector(getPushesState, shallowEqual);
  const {
    callStatus,
    stickyStatus,
    doctor = {},
    startTime,
    endTime,
    isInitialTime = true,
  } = useSelector(getPatientAppointmentState, shallowEqual);

  const validatePermissions = async () => {
    if (['/patient/login'].indexOf(location.pathname) === -1) {
      const result = await Notification.permission;
      setTimeout(() => {
        if (result !== 'granted') {
          NavigationService.navigate('/support/permissions');
        }
      }, 500);
    }
  };

  useEffect(() => {
    validatePermissions();
  }, [location.pathname]);

  const timeUp = () => {
    dispatch(appointmentLocalStepsRequest({ isInitialTime: false }));
  };
  const returnToCall = () => {
    if (localStorage.getItem('Answer_Call') !== 'true') {
      navigate('/patient/consultations');
    } else {
      dispatch(appointmentLocalStepsRequest({ callStatus: 'active' }));
      navigate('/patient/video-call');
    }
  };

  useEffect(() => {
    if (['/patient/video-call'].indexOf(location.pathname) !== -1) {
      if (localStorage.getItem('Answer_Call') !== 'true') {
        navigate('/patient/consultations');
      }
    }
    registerPatientLocalEvents();

    return () => {
      removePatientLocalEvents();
    };
  }, []);

  const [isPageVisibleAgain, setIsPageVisibleAgain] = useState(false);
  const onVisibilityChange = () => setIsPageVisibleAgain(Utils.getIsDocumentHidden());

  useEffect(() => {
    if (isLoggedIn) {
      (async () => {
        await PatientPushNotifier.updateDeviceRegId();
        dispatch(fetchPushesRequest({}));
        dispatch(fetchPatientProfileRequest({}));
        dispatch(fetchNotificationsSettingsRequest({}));
        dispatch(getCurrentAppointmentRequest({}));
        dispatch(getAppointmentSettingsRequest({}));
      })();

      document.addEventListener(
        Utils.getBrowserVisibilityProp(document),
        onVisibilityChange,
        false,
      );

      return () => {
        document.removeEventListener(Utils.getBrowserVisibilityProp(document), onVisibilityChange);
      };
    }

    return () => {};
  }, [isLoggedIn]);

  useEffect(() => {
    if (isPageVisibleAgain) {
      dispatch(getCurrentAppointmentRequest({}));
    }
  }, [isPageVisibleAgain]);

  // if (Utils.isMobileDevice() && mustRedirectOnWeb()) {
  //   return <UnsupportedBrowser isDoctor={false} suggestOtherBrowser={false} />;
  // }
  //
  Utils.isSupportedBrowser();
  // if (!Utils.isSupportedBrowser() && !Utils.isMobileDevice()) {
  //   return <UnsupportedBrowser isDoctor={false} suggestOtherBrowser />;
  // }

  return (
    <div className="patient-app d-flex flex-column min-vh-100">
      <div className="container-fluid d-flex flex-column min-vh-100">
        <div className="row d-flex flex-column min-vh-100">
          <HeaderSection unseen={unseen} />

          <main
            className={`${
              mainContentAlignCenter() ? 'container mt-auto mb-auto' : 'container mb-auto'
            }`}
          >
            {[
              '/patient/video-call',
              '/patient/appointment/waiting-screen',
              '/patient/call-specialist/waiting-screen',
              '/patient/call-specialist/summary-payment',
              '/patient/call-specialist/medic-found',
              '/patient/call-specialist/searching-medic',
              '/patient/appointment/medical-documents',
              '/patient/call-specialist/profile',
              '/patient/call-specialist/select-specialization',
              '/patient/login',
            ].indexOf(location.pathname) === -1 &&
              isLoggedIn && (
                <PatientStickyBar
                  theme={theme}
                  callStatus={callStatus}
                  stickyStatus={stickyStatus}
                  doctor={doctor}
                  startTime={startTime}
                  endTime={endTime}
                  isInitialTime={isInitialTime}
                  timeUp={timeUp}
                  returnToCall={returnToCall}
                />
              )}
            <PatientRoutes
              isLoggedIn={isLoggedIn}
              hasFullAccess={hasFullAccess}
              maintenanceMode={false}
            />
            <div className="push" />
          </main>

          <FooterSection />
        </div>
      </div>
    </div>
  );
};

export default PatientApp;
