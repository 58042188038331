import 'patient/styles/appointment-steps.css';
import React, { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import ListGroup from 'react-bootstrap/ListGroup';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import {
  getLocalChooseVoucherState,
  getPatientAppointmentState,
  getPatientProfileState,
  getValidVouchersState,
} from 'shared/redux/src/StatesGetter';
import OtherConstant from 'shared/constants/OtherConstants.json';
import { fetchValidVouchersRequest } from 'shared/redux/actions/VouchersActions';
import { minutesUntil } from 'shared/modules/DateTimeUtils';
import PaymentCardComponent from 'patient/components/PaymentCardComponent';
import PaymentVoucherComponent from 'patient/components/PaymentVoucherComponent';
import CustomIcon from 'shared/components/CustomIcon';
import Colors from 'shared/themes/Colors';
import Alerts from 'shared/components/Alerts';
import { appointmentCancelRequest } from 'shared/redux/actions/PatientAppointmentActions';
import i18next from 'i18next';
import { loadStripe } from '@stripe/stripe-js';
import { Elements } from '@stripe/react-stripe-js';
import { StripeElementLocale } from '@stripe/stripe-js/types/stripe-js/elements-group';
import dayjs from 'dayjs';
import { getEnv } from '../../../shared/services/EnvService';
import PaymentInvoicingAddress from '../../components/PaymentInvoicingAddress';

const stripePromise = loadStripe(`${getEnv('STRIPE_PK_KEY')}`);

const PatientAppointmentSummaryPayment = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const {
    id: appointmentId,
    doctor: foundDoctor,
    price: priceString,
    priceGrossAsString,
    startTime,
    noOfSteps,
    duration,
    type: appointmentType,
    scheduledAt = dayjs().format('YYYY-MM-DD HH:mm:ss'),
    voucher: serverVoucher,
  } = useSelector(getPatientAppointmentState, shallowEqual);

  const {
    id: patientId,
    hasCompleteAddress,
    address,
  } = useSelector(getPatientProfileState, shallowEqual);
  const skipPayment = serverVoucher?.campaign?.skip_payment;

  const vouchers = useSelector(getValidVouchersState, shallowEqual);
  const localChosenVoucher = useSelector(getLocalChooseVoucherState, shallowEqual);
  const stepString = `${t('step')} ${noOfSteps}/${noOfSteps}`;

  useEffect(() => {
    if (appointmentId) {
      dispatch(fetchValidVouchersRequest({ id: appointmentId }));
    }
  }, []);

  const cancelAppointment = () => {
    Alerts.okCancelAlert(t('warning'), t('validations.cancelCallSpecialis'), () => {
      dispatch(appointmentCancelRequest({ appointmentId }));
      navigate('/patient/medicList');
    });
  };

  const skippablePayment = () => {
    const minutesUntilAppointment = minutesUntil(startTime);
    return (
      appointmentType === 'programmed' &&
      (minutesUntilAppointment.days > 0 ||
        (minutesUntilAppointment.days === 0 &&
          minutesUntilAppointment.hours >= OtherConstant.PAYMENT_THRESHOLD_HOURS))
    );
  };

  const stripeOptions = (language) => {
    return {
      locale: language as StripeElementLocale,
    };
  };
  // Warning: Unsupported prop change on Elements: You cannot change the `stripe` prop after setting it.
  // TODO: Find a way to change the locale on existing stripe instance
  useEffect(() => {
    stripeOptions(i18next.language);
  }, [i18next.language]);

  return (
    <section className="appointment-steps">
      <Elements stripe={stripePromise} options={stripeOptions(i18next.language)}>
        <div className="title-section">
          <div className="steps">
            <div className="icon-wrapper" onClick={() => navigate(-1)}>
              <CustomIcon
                className="custom-icon"
                color={Colors.darkGrey}
                size="24"
                icon="Left_arrow"
              />
            </div>
            {stepString}
            <div className="cancel-button" onClick={cancelAppointment}>
              <button type="button">{t('abort')}</button>
            </div>
          </div>
          <h1>{t('summaryAndPayment')}</h1>
        </div>
        <div className="medic-box">
          <div
            className="avatar"
            style={
              foundDoctor?.imgUrl
                ? { backgroundImage: `url(${foundDoctor?.imgUrl})` }
                : { backgroundColor: '#E5E7E8' }
            }
          >
            {!foundDoctor?.imgUrl && (
              <div className="image-placeholder">{`${foundDoctor?.firstName?.charAt(
                0,
              )}${foundDoctor?.lastName?.charAt(0)}`}</div>
            )}
          </div>
          <div className="info">
            <div className="name">{foundDoctor.fullNameWithTitle}</div>
            <div className="specialization">{foundDoctor.specializationName}</div>
          </div>
        </div>
        <div className="appointment-details-box">
          <ListGroup>
            <ListGroup.Item>
              <div className="title">{t('dateAndTime')}</div>
              <div className="details">
                {dayjs(scheduledAt ?? dayjs()).format('DD MMM YYYY, HH:mm')}
              </div>
            </ListGroup.Item>
            <ListGroup.Item>
              <div className="title">{t('appointments.duration')}</div>
              <div className="details">{duration} min</div>
            </ListGroup.Item>
            {serverVoucher?.id && (
              <ListGroup.Item>
                <div className="title">{t('settings.voucher')}</div>
                <div className="details">-{serverVoucher.discount_as_string}</div>
              </ListGroup.Item>
            )}
            <ListGroup.Item>
              <div className="title">{t('totalToPay')}</div>
              <div className="details">
                {serverVoucher?.id && (
                  <span style={{ color: '#7e7e7e', marginRight: '5px' }}>
                    <s>{priceGrossAsString}</s>
                  </span>
                )}
                <span>{priceString}</span>
              </div>
            </ListGroup.Item>
          </ListGroup>
        </div>
        <PaymentVoucherComponent
          appointmentId={appointmentId}
          vouchers={vouchers}
          serverVoucher={serverVoucher}
          localChosenVoucher={localChosenVoucher}
        />

        <PaymentInvoicingAddress
          hasCompleteAddress={hasCompleteAddress}
          address={address}
          patientId={patientId}
        />

        <PaymentCardComponent
          appointmentId={appointmentId}
          skippablePayment={skippablePayment()}
          appointmentType={appointmentType}
          priceString={priceString}
          skipPayment={skipPayment}
        />
      </Elements>
    </section>
  );
};

export default PatientAppointmentSummaryPayment;
